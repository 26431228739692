<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-warning con-icon" v-if="!data.unit.id">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Pilih unit terlebih dahulu pada tab <strong>Booking & Properti</strong>.
          </div>
        </div>
      </div>
    </div>

    <div :class="{'opacity-50 pointer-events-none': !data.unit.id}">
      <vs-divider>Harga Unit</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">HPP Tanah</label>
          <v-money class="w-full" v-model="data.unit.hpp_tanah" disabled/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">HPP Bangunan</label>
          <v-money class="w-full" v-model="data.unit.hpp_bangunan" disabled/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total HPP</label>
          <v-money class="w-full" :value="(data.unit.hpp_tanah + data.unit.hpp_bangunan)" disabled/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Harga Standar *</label>
          <v-money class="w-full" v-model="data.unit.harga_standar" :readonly="!$can('update_harga_unit')"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Kel. Luas</label>
          <v-money class="w-full" v-model="data.unit.harga_luas" :readonly="!$can('update_harga_unit')"/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Kel. Tinggi</label>
          <v-money class="w-full" v-model="data.unit.harga_tinggi" :readonly="!$can('update_harga_unit')"/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya View</label>
          <v-money class="w-full" v-model="data.unit.harga_view" :readonly="!$can('update_harga_unit')"/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Mutu</label>
          <v-money class="w-full" v-model="data.unit.harga_mutu" :readonly="!$can('update_harga_unit')"/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Adm</label>
          <v-money class="w-full" v-model="data.unit.biaya_adm" :readonly="!$can('update_harga_unit')"/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Peningkatan Hak</label>
          <v-money class="w-full" v-model="data.unit.biaya_notaris" :readonly="!$can('update_harga_unit')"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">DPP *</label>
          <v-money class="w-full" :value="dpp" disabled/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Total Harga Unit</label>
          <v-money class="w-full" :value="totalHargaUnit" :danger="isHargaUnitError" :danger-text="errorTextHargaUnit" disabled/>
        </div>
      </div>

      <vs-divider class="mt-base">Pembayaran</vs-divider>
      <div class="vx-row mb-5">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Rencana Bayar</label>
          <v-select :options="caraBayars" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_cara_bayar"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Bank</label>
          <v-select :options="bankKprs" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_bank_kpr"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">COA Debet *</label>
          <vx-input-group>
            <vs-input placeholder="Pilih COA" :value="data.nama_coa_debet" @click="modalCoa.active = true" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalCoa.active = true"/>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Diskon</label>
          <v-input-percentage :total="totalHargaUnit" :percentage.sync="data.diskon_persen" :amountage.sync="data.diskon_jml" :danger="isDiskonError" :danger-text="errorTextDiskon"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPN</label>
          <v-input-percentage :total="dpp" :percentage.sync="data.ppn_persen" :amountage.sync="data.ppn_jml" :danger="isPpnError" :danger-text="errorTextPpn"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPh Jenis *</label>
          <vs-select class="w-full" autocomplete :value="data.pph_key" @input="onChangePphJenis">
            <vs-select-item v-for="(item, index) in listPphJenis" :key="index" :value="item.key" :text="item.alias + ' - ' + item.value + '%'"/>
          </vs-select>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPh *</label>
          <v-input-percentage class="w-full" :total="dpp" :percentage.sync="data.pph_persen" :amountage.sync="data.pph_jml" :disabled="true"/>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right">
            <span class="text-xs">Total Harga Jual</span>
            <strong> = {{ totalHargaJual | idr }}</strong>
          </h5>
        </div>
      </div>

      <vs-divider class="mt-base">Booking Fee</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Cara Bayar Booking Fee *</label>
          <v-select :options="caraBayarBookingFees" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_cara_bayar_bf"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tgl. Jatuh Tempo *</label>
          <flat-pickr class="w-full" v-model="data.booking_fee_due"></flat-pickr>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Booking Fee Status *</label>
          <v-select :options="boofeeStatuses" label="nama" :reduce="item => item.kode" :clearable="false" v-model="data.booking_fee_status"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Total Booking Fee *</label>
          <v-money class="w-full" v-model="data.booking_fee"/>
        </div>
      </div>
      <div class="vx-row my-6">
      </div>

      <vs-divider class="mt-base">Uang Muka</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Uang Muka</label>
          <v-input-percentage :total="totalHargaJual" :percentage.sync="data.dp_persen" :amountage.sync="data.dp_jml" :danger="isDpError" :danger-text="errorTextDP"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Termin Uang Muka ( X )</label>
          <vs-input class="w-full" type="number" v-model="data.termin_dp" :danger="isTerminDpError" :danger-text="errorTextTerminDP" :disabled="!data.dp_jml > 0"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total Uang Muka</label>
          <v-money class="w-full" :value="totalUangMuka" disabled/>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right">
            <span class="text-xs">Sisa Uang Muka</span>
            <strong> = {{ totalSisaUangMuka | idr }}</strong>
          </h5>
        </div>
      </div>

      <vs-divider class="mt-base">Angsuran</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Sisa Angsuran</label>
          <v-money class="w-full" :value="totalSisaAngsuran" disabled/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Termin Angsuran ( X ) *</label>
          <vs-input class="w-full" type="number" v-model="data.termin_angsuran" :danger="isTerminAngsuranError" :danger-text="errorTextTerminAngsuran"/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Tgl. Mulai Jatuh Tempo *</label>
          <flat-pickr class="w-full" v-model="data.angsuran_start_due"></flat-pickr>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right">
            <span class="text-xs">Sisa Angsuran</span>
            <strong> = {{ totalSisaAngsuran | idr }}</strong>
          </h5>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right font-bold">
            Grand Total = {{ grandTotal | idr }}
          </h5>
        </div>
      </div>

      <vs-divider class="mt-base">Review Termin</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <div class="flex flex-wrap justify-between items-center mb-1">
            <div class="mb-4 md:mb-0 mr-4">
              <div class="flex space-x-3">
                <vs-button color="success" icon-pack="feather" icon="icon-plus" :disabled="!isCanGenerateTermins" @click="generateTermins" class="py-2 px-3">Generate Termin</vs-button>
                <vs-button color="primary" icon-pack="feather" icon="icon-refresh-cw" :disabled="!isRefreshTerminNeeded" @click="refreshTermins" class="py-2 px-3">Refresh Termin</vs-button>
                <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.angsurans.length < 1" @click="clearTermins" class="py-2 px-3">Clear</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <vs-table :data="data.angsurans" stripe class="fix-table-input-background">
        <template slot="thead" class="text-sm">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Termin Ke</vs-th>
          <vs-th class="whitespace-no-wrap">Tipe</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Persen Bayar</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Jumlah (DPP+PPN)</vs-th>
          <vs-th class="whitespace-no-wrap text-right">DPP</vs-th>
          <vs-th class="whitespace-no-wrap text-right">PPN</vs-th>
          <vs-th class="whitespace-no-wrap text-right">PPh</vs-th>
          <vs-th class="whitespace-no-wrap">Due Date</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Keterangan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
            <vs-td class="whitespace-no-wrap"><span class="font-bold" :class="['text-' + getColorOfTipeAngsuran(item.tipe_angsuran)]">Ke - {{ item.no_termin }}</span></vs-td>
            <vs-td class="whitespace-no-wrap">
              <span class="inline-flex items-center whitespace-no-wrap justify-center p-2 text-xs leading-none text-white rounded-full" :class="['bg-' + getColorOfTipeAngsuran(item.tipe_angsuran)]">
                {{ item.tipe_angsuran }}
              </span>
            </vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.persen | idr(2) }}%</vs-td>
            <vs-td class="whitespace-no-wrap">
              <v-money class="w-48" theme="theme4" v-model="item.jml_nominal" @input="updateTerminItemCalculation(index)" :disabled="item.tipe_angsuran === 'BOOKING FEE'"/>
              <p v-if="index === $data.data.angsurans.length - 1 && footerTermin.totalPercent.toFixed(2).toString() !== '100.00'"
                    @click="adjustNominalForLastTermin"
                    class="text-xs underline text-primary text-right cursor-pointer">Set Sisa</p>
            </vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.dpp | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_ppn | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_pph | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="w-40">
                <flat-pickr class="w-full" v-model="item.tgl_due"></flat-pickr>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap"><vs-input class="w-64" placeholder="Keterangan" v-model="item.keterangan"/></vs-td>
          </vs-tr>
          <!--footer-->
          <vs-tr v-if="$data.data.angsurans.length > 0" class="text-sm">
            <vs-td class="font-bold">Total</vs-td>
            <vs-td class="text-right font-bold" colspan="3">{{ footerTermin.totalPercent | idr(2) }}%</vs-td>
            <vs-td class="text-right font-bold"><span class="mr-3" :class="{'text-danger': footerTermin.totalPercent.toFixed(2).toString() !== '100.00'}">{{ footerTermin.totalNominal | idr }}</span></vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalDpp | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalPpn | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalPph | idr }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div class="vx-row float-right mt-10">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data COA"
              :active="modalCoa.active"
              v-on:update:active="modalCoa.active = $event">
      <Coa :externalFilter="filterCoa" :selectable="true" @selected="onSelectedModalCoa"/>
    </vs-popup>

  </div>
</template>

<script>
import Coa from '@/views/pages/master/coa/Coa'
import VMoney from '@/views/components/v-money/VMoney'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VInputPercentage from '@/views/components/v-input-percentage/VInputPercentage'
import tabAngsuranMixin from '@/views/pages/marketing/booking/mixins/tab-angsuran/tabAngsuranMixin'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'TabAngsuran',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  mixins: [tabAngsuranMixin],
  components: {
    ValidationErrors,
    VInputPercentage,
    flatPickr,
    VMoney,
    Coa,
    'v-select': vSelect
  },
  computed: {
    errors () {
      return this.$store.state.marketing.bookingAdd.errors.tabAngsuran.messages
    },
    storeTabProperti () {
      return this.$store.state.marketing.bookingAdd.tabProperti
    },
    filterCoa () {
      return {
        id: 137,
        tipe: 'DETAIL'
      }
    }
  },
  mounted () {
    this.getAllJenisPph()
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    storeTabProperti (newVal, oldVal) {
      this.onChangeStoreTabProperti(newVal, oldVal)
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      modalCoa: {
        active: false
      },
      data: {
        id_coa_debet: 137,
        nama_coa_debet: 'Piutang Customer',
        id_cara_bayar: null,
        id_cara_bayar_bf: null,
        id_bank_kpr: null,
        unit: {},
        angsurans: []
      }
    }
  },
  methods: {
    onSelectedModalCoa (item) {
      this.data.id_coa_debet = item.id
      this.data.nama_coa_debet = item.nama
      this.modalCoa.active = false
    },

    onChangeStoreTabProperti (newVal) {
      this.data.unit = _.cloneDeep(newVal.unit)
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      payload.unit.totalHargaUnit = this.totalHargaUnit
      payload.totalHargaJual = this.totalHargaJual
      payload.grandTotal = this.grandTotal
      payload.totalSisaAngsuran = this.totalSisaAngsuran
      payload.dpp = this.dpp
      payload.isRefreshTerminNeeded = this.isRefreshTerminNeeded
      this.$store.commit('marketing/bookingAdd/SET_TAB_ANGSURAN', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', 1)
    },

    next () {
      this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', 3)
    }
  }
}
</script>
