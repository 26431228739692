<template>
  <div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Tgl. Booking *</label>
        <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">No. Booking</label>
        <vs-input class="w-full" v-model="data.no_booking" placeholder="Generate Otomatis" disabled/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Staf Marketing *</label>
        <vx-input-group>
          <vs-input v-model="data.nama_staf" @click="modalStaf.active = true" readonly/>
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalStaf.active = true"/>
            </div>
          </template>
        </vx-input-group>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Koordinator</label>
        <vs-input class="w-full" v-model="data.nama_atasan_staf" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full -mt-1">
        <label class="ml-1 text-xs">Keterangan Booking</label>
        <vs-textarea class="w-full" v-model="data.keterangan_booking"/>
      </div>
    </div>

    <vs-divider> Detail Properti </vs-divider>

    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Proyek *</label>
        <vs-select v-model="data.id_proyek" class="w-full" autocomplete @input="onChangeProyek" disabled>
          <vs-select-item v-for="(item, index) in proyeks" :key="index" :value="item.id" :text="item.nama"/>
        </vs-select>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Properti Unit *</label>
        <vs-select class="w-full" autocomplete v-model="data.id_properti_unit" @input="onChangePropertiUnit">
          <vs-select-item :class="{'vs-select-item-text-danger': item.status_unit !== 'OPEN'}" v-for="(item, index) in propertiUnits" :key="index" :value="item.id" :text="item.no_unit" :disabled="item.status_unit !== 'OPEN'"/>
        </vs-select>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Properti Kategori</label>
        <vs-input class="w-full" v-model="data.unit.nama_properti_kategori" readonly/>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Properti Tipe</label>
        <vs-input class="w-full" v-model="data.unit.nama_properti_tipe" readonly/>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Cluster</label>
        <vs-input class="w-full" v-model="data.unit.nama_cluster" readonly/>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Luas Tanah (m<sup>2</sup>)</label>
        <vs-input class="w-full" v-model="data.unit.luas_tanah" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Luas Bangunan (m<sup>2</sup>)</label>
        <vs-input class="w-full" v-model="data.unit.luas_bangunan" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Luas Efektif (m<sup>2</sup>)</label>
        <vs-input class="w-full" v-model="data.unit.luas_efektif" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">View</label>
        <vs-input class="w-full" v-model="data.unit.view" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Lokasi</label>
        <vs-input class="w-full" v-model="data.unit.lokasi" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Spesifikasi</label>
        <vs-input class="w-full" v-model="data.unit.spesifikasi" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-input class="w-full" v-model="data.unit.keterangan" readonly/>
      </div>
    </div>
    <div class="vx-row float-right mt-6">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" type="border" icon-pack="feather" icon="icon-arrow-left">Kembali</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Lanjut</vs-button>
      </div>
    </div>

  <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
            title="Pilih Data Staf"
            :active="modalStaf.active"
            v-on:update:active="modalStaf.active = $event">
    <Staf :selectable="true"
          :externalFilter="filterStaf"
          @selected="onSelectedModalStaf"/>
  </vs-popup>

  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import Staf from '@/views/pages/master/staf/Staf'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'TabProperti',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    Staf,
    flatPickr,
    ValidationErrors
  },
  mounted () {
    this.getAllProyek()
  },
  computed: {
    errors () {
      return this.$store.state.marketing.bookingAdd.errors.tabProperti.messages
    },
    storeTabCustomer () {
      return this.$store.state.marketing.bookingAdd.tabCustomer
    },
    filterStaf () {
      return { id_proyek: this.data.id_proyek }
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
        this.getAllPropertiUnit()
      }
    },
    storeTabCustomer (newVal, oldVal) {
      this.onChangeStoreTabCustomer(newVal, oldVal)
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      isLoading: false,
      proyeks: [],
      propertiUnits: [],
      data: {
        tgl: moment().format('YYYY-MM-DD'),
        unit: {}
      },
      modalStaf: {
        active: false
      }
    }
  },

  methods: {
    onSelectedModalStaf (item) {
      this.data.id_staf = item.id
      this.data.nama_staf = item.nama
      this.data.nama_atasan_staf = item.nama_atasan
      this.modalStaf.active = false
    },

    onChangeStoreTabCustomer (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        const aktifitasMarketing = _.cloneDeep(newVal.aktifitasMarketing)
        this.data.id_proyek = aktifitasMarketing.id_proyek
        this.data.id_staf = aktifitasMarketing.id_staf
        this.data.nama_staf = aktifitasMarketing.nama_staf
        this.data.nama_atasan_staf = aktifitasMarketing.nama_atasan_staf
        this.onChangeProyek()
      }
    },

    onChangeProyek () {
      this.propertiUnits = []
      this.data.unit = {}
      this.data.id_properti_unit = null
      if (this.data.id_proyek) this.getAllPropertiUnit()
    },

    onChangePropertiUnit (idPropertiUnit) {
      const unit = _.find(this.propertiUnits, item => item.id === idPropertiUnit)
      this.data.unit = {
        ...unit,
        harga_standar: (unit.harga_standar_tanah + unit.harga_standar_bangunan)
      }
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllPropertiUnit () {
      const params = {}
      params.filter = JSON.stringify({ id_proyek: this.data.id_proyek })

      PropertiUnitRepository.getAll(params)
        .then(response => {
          this.propertiUnits = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('marketing/bookingAdd/SET_TAB_PROPERTI', payload)
    }, 500),

    prev () {
      this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', 0)
    },

    next () {
      this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', 2)
    }
  }
}
</script>
