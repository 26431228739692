<template>
  <div>
    <div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mt-3 mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Aktifitas Marketing *</label>
          <vx-input-group>
            <vs-input v-model="data.aktifitasMarketing.no_bukti" @click="modalAktifitasMarketingActive = true" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalAktifitasMarketingActive = true"/>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>

      <div :class="{'pointer-events-none opacity-50': !data.aktifitasMarketing.id_aktifitas_marketing}">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Kode Customer *</label>
            <vs-input class="w-full" v-model="data.customer.kode" disabled/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Nama *</label>
            <vs-input class="w-full" v-model="data.customer.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">No. Identitas</label>
            <vs-input class="w-full" v-model="data.customer.no_identitas"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Gender</label>
            <v-select :options="genders" label="nama" :reduce="item => item.gender" :clearable="false" v-model="data.customer.gender"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Tempat Lahir</label>
            <vs-input class="w-full" v-model="data.customer.tmp_lahir"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Tanggal Lahir</label>
            <flat-pickr class="w-full" v-model="data.customer.tgl_lahir"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Telp</label>
            <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-phone" v-model="data.customer.telp"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">HP *</label>
            <vs-input class="w-full" type="tel" icon-pack="feather" icon="icon-smartphone" v-model="data.customer.hp"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">E-mail *</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" v-model="data.customer.email"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Agama</label>
            <vs-input class="w-full" v-model="data.customer.agama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Kelurahan</label>
            <vs-input class="w-full" v-model="data.customer.kelurahan"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Kota</label>
            <vs-input class="w-full" v-model="data.customer.kota"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Kode Pos</label>
            <vs-input class="w-full" type="number" v-model="data.customer.pos"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Tujuan Pembelian</label>
            <vs-input class="w-full" v-model="data.customer.tujuan_pembelian"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Domisili</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.domisili"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Alamat *</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Sumber Dana</label>
            <vs-input class="w-full" v-model="data.customer.sumber_dana"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Status Nikah</label>
            <vs-input class="w-full" v-model="data.customer.status_nikah"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Nama Pasangan</label>
            <vs-input class="w-full" v-model="data.customer.nama_pasangan"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Status Tempat Tinggal</label>
            <vs-input class="w-full" v-model="data.customer.status_tmptgl"/>
          </div>
        </div>

        <vs-divider class="mt-10">Pekerjaan</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Profesi *</label>
            <v-select :options="profesis" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.customer.id_profesi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Status Profesi</label>
            <vs-input class="w-full" v-model="data.customer.status_profesi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Jabatan</label>
            <vs-input class="w-full" v-model="data.customer.jabatan"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">NPWP</label>
            <vs-input class="w-full" type="number" v-model="data.customer.npwp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Alamat NPWP</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat_npwp"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Alamat Surat</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat_surat"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">No. Rekening</label>
            <vs-input class="w-full" type="number" v-model="data.customer.no_rekening"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Nama Instansi</label>
            <vs-input class="w-full" v-model="data.customer.nama_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Telp. Instansi</label>
            <vs-input class="w-full" type="number" icon-pack="feather" icon="icon-phone" v-model="data.customer.telp_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Fax Instansi</label>
            <vs-input class="w-full" type="number" icon-pack="feather" icon="icon-printer" v-model="data.customer.fax_instansi"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Alamat Instansi</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.customer.alamat_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">CP Instansi</label>
            <vs-input class="w-full" v-model="data.customer.cp_instansi"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <label class="ml-1 text-xs">Jenis Usaha</label>
            <vs-input class="w-full" v-model="data.customer.jenis_usaha"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Penghasilan Tetap</label>
            <v-money class="w-full" v-model="data.customer.pengh_tetap"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Penghasilan Tambahan</label>
            <v-money class="w-full" v-model="data.customer.pengh_tambah"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <label class="ml-1 text-xs">Penghasilan Bulanan</label>
            <v-money class="w-full" v-model="data.customer.pengh_bulanan"/>
          </div>
        </div>
      </div>

      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full">
          <vs-button :disabled="isLoading" @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>
            <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
            <span v-if="!isLoading">Lanjut</span>
          </vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Data Aktifitas Marketing"
              :active="modalAktifitasMarketingActive"
              v-on:update:active="modalAktifitasMarketingActive = $event">
      <AktifitasMarketing :selectable="true"
                          :externalFilter="filterAktifitasMarketing"
                          @selected="onSelectedModalAktifitasMarketing"/>
    </vs-popup>

  </div>
</template>

<script>
import ProfesiRepository from '@/repositories/master/profesi-repository'
import CustomerRepository from '@/repositories/master/customer-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import AktifitasMarketing from '@/views/pages/marketing/aktifitas-marketing/AktifitasMarketing'
import VMoney from '@/views/components/v-money/VMoney'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'TabCustomer',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    AktifitasMarketing,
    VMoney,
    flatPickr,
    ValidationErrors,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProfesi()
  },
  computed: {
    errors () {
      return this.$store.state.marketing.bookingAdd.errors.tabCustomer.messages
    },
    filterAktifitasMarketing () {
      return { status: 'HOT' }
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      modalAktifitasMarketingActive: false,
      isModalCustomerActive: false,
      isLoading: false,
      data: {
        customer: {},
        aktifitasMarketing: {}
      },
      genders: [
        { gender: 'L', nama: 'Laki-laki' },
        { gender: 'P', nama: 'Perempuan' }
      ],
      profesis: []
    }
  },
  methods: {
    async onSelectedModalAktifitasMarketing (item) {
      this.modalAktifitasMarketingActive = false
      this.data = {
        aktifitasMarketing: item,
        customer: await this.getCustomerById(item.id_customer)
      }
    },

    onSelectedModalCustomer (item) {
      this.data.customer = item
      this.isModalCustomerActive = false
    },

    getAllProfesi () {
      ProfesiRepository.getAllProfesi()
        .then(response => {
          this.profesis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getCustomerById (idCustomer) {
      return await CustomerRepository.show(idCustomer)
        .then(response => {
          return response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('marketing/bookingAdd/SET_TAB_CUSTOMER', payload)
    }, 500),

    next () {
      this.$store.commit('marketing/bookingAdd/SET_ACTIVE_TAB', 1)
    }
  }
}
</script>
